import React from "react";
import linkedin from "../../assets/img/linkedin.svg";
import wilsonJoel from "../../assets/img/wilson-joel.svg";
import iqbalMarcus from "../../assets/img/iqbal-marcus.svg";
import allMembers from "../../assets/img/lineage-members.svg";

export default function MeetTheTeam() {
  const staff = [
    {
      img: wilsonJoel,
      name1: "WILSON TAY",
      position1: "CHIEF EXECUTIVE OFFICER",
      linkedin1: "https://www.linkedin.com/in/wilsontay/",
      name2: "JOEL NG",
      position2: "HEAD OF ECOSYSTEM",
      linkedin2: "https://www.linkedin.com/in/joel-ng-25391850/",
    },
    {
      img: iqbalMarcus,
      name1: "IQBAL BAHARUM",
      position1: "CHIEF ARCHITECT",
      linkedin1: "https://www.linkedin.com/in/iqbalbaharum/",
      name2: "MARCUS FOO",
      position2: "HEAD OF OPERATIONS",
      linkedin2: "https://www.linkedin.com/in/marcus-foo-91231760/",
    },
  ];

  const members = [
    {
      name: "WILSON TAY",
      position: "CHIEF EXECUTIVE OFFICER",
      linkedin: "https://www.linkedin.com/in/wilsontay/",
    },
    {
      name: "JOEL NG",
      position: "HEAD OF ECOSYSTEM",
      linkedin: "https://www.linkedin.com/in/joel-ng-25391850/",
    },
    {
      name: "IQBAL BAHARUM",
      position: "CHIEF ARCHITECT",
      linkedin: "https://www.linkedin.com/in/iqbalbaharum/",
    },
    {
      name: "MARCUS FOO",
      position: "HEAD OF OPERATIONS",
      linkedin: "https://www.linkedin.com/in/marcus-foo-91231760/",
    },
  ];
  return (
    <div
      className="my-40 bg-nversebg bg-no-repeat bg-cover lg:bg-auto lg:bg-center 2xl:bg-contain 2xl:pt-[12rem]"
      id="team"
    >
      <br />
      <br />
      <br />
      <br />
      <div className="text-white">
        <p className="text-3xl lg:text-5xl 2xl:text-[3.5vw] Rubik-ExtraBold">
          Meet The Team
        </p>
        <div className="flex flex-col items-center">
          <p className="text-white w-[270px] sm:w-[400px] lg:w-[700px] xl:w-[800px] 2xl:w-[2000px] mt-10 2xl:mt-44 text-md sm:text-[18px] xl:text-[28px] 2xl:text-[2vw]">
            Nverse is spearheaded by a team of developers, innovators and
            investors laser-focused on transforming digital asset ownership.
          </p>
        </div>
      </div>
      <div className="block md:hidden">
        {staff.map((item) => (
          <div className="mt-5">
            <div className="text-white">
              <img src={item.img} alt="" className="mx-auto" />
              <div className="flex flex-row justify-center w-[20rem] mx-auto -mt-8">
                <div className="mx-auto">
                  <p className=" text-[14px]">{item.name1}</p>
                  <p className="text-[10px] text-[#AEC9F7]">{item.position1}</p>
                  <a href={item.linkedin1} target="blank">
                    <div className="flex flex-row justify-center">
                      <img
                        src={linkedin}
                        alt=""
                        className="scale-[1.5] mt-1 mb-3"
                      />
                    </div>
                  </a>
                </div>
                <div className="mx-auto">
                  <p className=" text-[14px]">{item.name2}</p>
                  <p className="text-[10px] text-[#AEC9F7]">{item.position2}</p>
                  <a href={item.linkedin2} target="blank">
                    <div className="flex flex-row justify-center">
                      <img
                        src={linkedin}
                        alt=""
                        className="scale-[1.5] mt-1 mb-3"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="hidden md:block mt-10">
        <div className="text-white">
          <img src={allMembers} alt="" className="w-full 2xl:scale-[0.8]" />
          <div className="w-full 2xl:w-[65%] 2xl:m-auto">
            <div className="flex flex-row justify-center">
              {members.map((item) => (
                <div className="md:w-[20%] lg:w-[20%] xl:w-[22%] 2xl:w-1/4 xl:-mt-16 2xl:-mt-[10%]">
                  <div className="flex justify-center flex-col">
                    <p className="text-[14px] lg:text-[18px] 2xl:text-[1.7vw] font-black">
                      {item.name}
                    </p>
                    <p className="text-[#AEC9F7] lg:text-[13px] 2xl:text-[0.8vw] mt-1">
                      {item.position}
                    </p>
                    <div className="flex flex-row justify-center">
                      <a href={item.linkedin} target="blank">
                        <img
                          src={linkedin}
                          alt=""
                          className="scale-[1.5] 2xl:scale-[4] my-3 2xl:my-5"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
