import React, { useState } from "react";
import BG from "../../assets/main/bg.mp4";
import logo from "../../assets/icon/logo.svg";
import logoHover from "../../assets/logoHover.png";
import logoCenter from "../../assets/icon/main.svg";
import logoHoverCenter from "../../assets/icon/hover.svg";
import "../../assets/css/loading.css";
// import LoadingScreen from "../loading";

function LoadingMain() {
  const [isHover, setHover] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [isHoverMain, setHoverMain] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);
  return (
    <div className="w-full h-screen flex flex-col justify-start bg-gradient-to-b from-[#2B2E82] to-[#0A0918] bg-center bg-auto">
      {/* {loading ? (
        <LoadingScreen />
      ) : ( */}
      <div>
        <div className="absolute z-30 p-5 text-2xl text-white rounded-xl">
          <nav className=" z-50">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-16">
              <div className="flex items-start justify-between h-24">
                <div className="flex items-center justify-between w-full">
                  <div className="flex-shrink-0">
                    <a
                      href="/"
                      onMouseOver={() => setHover(true)}
                      onFocus={() => setHover(true)}
                      onMouseOut={() => setHover(false)}
                      onBlur={() => setHover(false)}
                    >
                      <img
                        className="h-16 w-[80%] 2xl:h-28"
                        src={isHover ? logoHover : logo}
                        alt="Nverse logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <header className="relative flex items-center justify-center h-screen overflow-hidden">
          <div className="relative z-30 p-5 text-2xl text-white rounded-xl">
            <div
              onMouseOver={() => setHoverMain(true)}
              onFocus={() => setHoverMain(true)}
              onMouseOut={() => setHoverMain(false)}
              onBlur={() => setHoverMain(false)}
            >
              <a href="/home">
                <img
                  className="w-[100%] -mt-[20%]"
                  src={isHoverMain ? logoHoverCenter : logoCenter}
                  alt="mainLogo"
                />

                <p className="text-white font-Gothic text-lg 2xl:text-5xl">
                  Click To Enter
                </p>
              </a>
            </div>
          </div>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute z-10 w-auto min-w-full min-h-full max-w-none overflow-hidden mix-blend-luminosity"
          >
            <source src={BG} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </header>
      </div>
      {/* )} */}
    </div>
  );
}

export default LoadingMain;
