import React from "react";
import lineageLogo from "../../assets/img/lineage.gif";

export default function BuildByNverse() {
  return (
    <div className="mt-20 xl:mt-[10rem] 2xl:pt-[12rem]" id="built">
      <br />
      <br />
      <br />
      <br />
      <a href="https://lineageprotocol.com/" target="blank">
        <p className="text-3xl 2xl:text-[3.5vw] sm:text-4xl text-white font-black">
          Built by Nverse
        </p>
        <div className="flex flex-col items-center">
          <p className="text-white w-[270px] sm:w-[400px] lg:w-[700px] xl:w-[800px] 2xl:w-[2000px] mt-10 2xl:mt-44 text-md sm:text-[20px] xl:text-[28px] 2xl:text-[2vw]">
            Nverse Labs is building the future of digital asset ownership.
            Pushing the boundaries of Web3 adoption.
          </p>
        </div>
        <div className="border-[0.5px] border-[##FFFFFF] rounded-3xl mt-16 2xl:mt-44 p-2 mx-auto w-[90%] 2xl:rounded-[6rem] sm:w-[70%] md:w-[60%] lg:w-[40%] 2xl:w-[35%]">
          <div className="bg-gradient-to-b from-[#0A0938] to-[#2C0938] rounded-3xl text-white py-6 px-4 2xl:rounded-[6rem]">
            <div className="-my-14">
              <img
                src={lineageLogo}
                alt="#lineageLogo"
                className="rotate-[110deg] mix-blend-screen h-[260px] xl:h-[340px] 2xl:h-[600px] mx-auto"
              />
            </div>
            <p className="nasalization text-[36px] 2xl:text-[2vw] tracking-widest">
              LINEAGE
            </p>
            <div className="w-[300px] sm:w-[400px] xl:w-[450px] 2xl:w-[650px] mt-8 2xl:my-3 flex flex-col mx-auto">
              <p className="text-start text-[16px] sm:text-[18px] xl:text-[26px] 2xl:text-[1.5vw] md:text-center lg:text-center 2xl:text-center">
                Lineage Protocol is a permissonless data mapping network for
                NFTs. Empowering the next generation of NFTs.
              </p>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
