import React from "react";
import { isMobile } from "react-device-detect";
import PartnersIcon from "../../assets/icon/partners/partners-icon.png";
import PartnersIconMoba from "../../assets/icon/partners/partnericonmobile.png";

function PartnersPage() {
  if (isMobile) {
    return (
      <div className="w-screen md:h-screen" id="partners">
        <div className="flex flex-col justify-center items-center">
          <div className="mt-[3%] font-semibold text-[1.5rem] my-4 text-white">
            Our Partners & Backers
          </div>
          <div className="w-[70%]">
            <div className="border-white border-2 rounded-3xl bg-white">
              <img
                src={PartnersIconMoba}
                alt="partners-icon"
                className="scale-90"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-screen h-screen" id="partners">
      <br />
      <div className="flex flex-col justify-center items-center">
        <div className="mt-[3%] font-semibold text-[2.5rem] p-4 text-white">
          Our Partners & Backers
        </div>
        <div className="w-[70%]">
          <div className="border-white border-2 rounded-3xl bg-white">
            <img src={PartnersIcon} alt="partners-icon" className="scale-90" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnersPage;
