import React, { useState } from "react";
// import { Transition } from "@headlessui/react";
// import { Link, useLocation } from "react-router-dom";
import ScrollIntoView from "react-scroll-into-view";

import logo from "../assets/img/logo1.svg";
import logoHover from "../assets/img/hoverlogo1.svg";

function Navbar() {
  const [hover, setHover] = useState(false);
  return (
    <div className="hidden lg:block">
      <nav className="z-10 fixed top-0 w-full md:w-[90vw] text-white flex flex-row justify-between items-center px-7 md:pl-20 py-[20px] 2xl:ml-[5%] 2xl:mt-[2%]">
        <a
          href="/"
          onMouseOver={() => setHover(true)}
          onFocus={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onBlur={() => setHover(false)}
        >
          <img
            src={hover ? logoHover : logo}
            alt="Nverse logo"
            className="h-[64px] object-contain scale-[0.8] 2xl:scale-[1.6]"
          />
        </a>
        <hr className="w-[90%] ml-[2%]" />
        <ul className="mx-2 hidden md:flex">
          <li className="mr-3">
            {/* <a className="text-blue-500 hover:text-blue-800" href="#">
              <div className="tooltip w-5 h-5 bg-transparent rounded-full border border-white">
                <span className="tooltiptext">Home</span>
              </div>
            </a> */}
            <ScrollIntoView selector="#home">
              <a className="text-blue-500 hover:text-blue-800 cursor-pointer">
                <div className="tooltip w-5 h-5 bg-transparent rounded-full border border-white 2xl:w-9 2xl:h-9">
                  <span className="tooltiptext 2xl:text-3xl">Home</span>
                </div>
              </a>
            </ScrollIntoView>
          </li>
          <li className="mr-3">
            <ScrollIntoView selector="#built">
              <a className="text-blue-500 hover:text-blue-800 cursor-pointer">
                <div className="tooltip w-5 h-5 bg-transparent rounded-full border border-white 2xl:w-9 2xl:h-9">
                  <span className="tooltiptext 2xl:text-3xl">Project</span>
                </div>
              </a>
            </ScrollIntoView>
          </li>
          <li className="mr-3">
            <ScrollIntoView selector="#team">
              <a className="text-blue-500 hover:text-blue-800 cursor-pointer">
                <div className="tooltip w-5 h-5 bg-transparent rounded-full border border-white 2xl:w-9 2xl:h-9">
                  <span className="tooltiptext 2xl:text-3xl">Team</span>
                </div>
              </a>
            </ScrollIntoView>
          </li>
          {/* <li className="mr-3">
            <ScrollIntoView selector="#partners">
              <a className="text-blue-500 hover:text-blue-800 cursor-pointer">
                <div className="tooltip w-5 h-5 bg-transparent rounded-full border border-white">
                  <span className="tooltiptext">Partners</span>
                </div>
              </a>
            </ScrollIntoView>
          </li>
          <li className="mr-0">
            <ScrollIntoView selector="#press">
              <a className="text-blue-500 hover:text-blue-800 cursor-pointer">
                <div className="tooltip w-5 h-5 bg-transparent rounded-full border border-white">
                  <span className="tooltiptext">Press</span>
                </div>
              </a>
            </ScrollIntoView>
          </li> */}
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
