import React, { useState } from "react";
import ScrollIntoView from "react-scroll-into-view";
import gif from "../../assets/img/intro-gif.gif";
import logo from "../../assets/img/logo1.svg";
import logoHover from "../../assets/img/hoverlogo1.svg";

export default function FirstPage() {
  const [hover, setHover] = useState(false);
  return (
    <div
      className="bg-earth lg:bg-earth-lg bg-cover bg-center bg-no-repeat border-black rounded-b-3xl"
      id="home"
    >
      <div className="block lg:hidden">
        <div className="flex flex-row justify-center">
          <a
            href="/"
            onMouseOver={() => setHover(true)}
            onFocus={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            onBlur={() => setHover(false)}
          >
            <img
              src={hover ? logoHover : logo}
              alt="Nverse logo"
              className="h-[64px] object-contain mt-10"
            />
          </a>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <img
          src={gif}
          alt=""
          className="mix-blend-screen mt-[2rem] sm:mt-[6rem] sm:scale-[1.2] md:mt-[12rem] md:scale-[1.4] 2xl:scale-[2] lg:mt-16 2xl:mt-[20rem] lg:relative lg:scale-[1.5] lg:mx-auto"
        />
        <p className="text-white text-[28px] w-[350px] font-bold mt-[7.5rem] sm:mt-[12rem] sm:w-auto md:mt-[18rem] lg:mt-[12rem] lg:text-[45px] 2xl:text-[2.5vw] lg:mx-auto">
          Advancing The Digital Asset Revolution
        </p>
        <button
          type="button"
          className="bg-[#4242D6] rounded-3xl my-[4rem] lg:my-[5rem] lg:mx-auto lg:mb-[10rem]"
        >
          <ScrollIntoView selector="#built">
            <a>
              <p className="text-white px-8 lg:px-12 2xl:px-28 2xl:py-10 py-2 text-lg lg:text-2xl 2xl:text-[2vw]">
                Learn More
              </p>
            </a>
          </ScrollIntoView>
        </button>
      </div>
    </div>
  );
}
