/* eslint-disable import/no-unresolved */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import { Autoplay } from "swiper";
import { isMobile } from "react-device-detect";

import Chain from "../../assets/icon/press/chain.png";
import Blockchain from "../../assets/icon/press/blockchain.png";
import Calender from "../../assets/icon/press/calender-icon.png";
import Person from "../../assets/icon/press/person-icon.png";
import Chat from "../../assets/icon/press/chat-icon.png";
import ChainMobile from "../../assets/icon/press/chain-mobile.png";
import BlockchainMobile from "../../assets/icon/press/blockchain-mobile.png";
import "../../css/press-swiper.css";

const list = [
  {
    image: Chain,
    mobile: ChainMobile,
    title: "Press One 69696969",
    description:
      "Maecenas cursus ultrices nibh, eu fringilla leo vulputate ut. Duis convallis ullamcorper nisi, non blandit eros rutrum eu. Morbi et",
    calender: Calender,
    person: Person,
    chat: Chat,
  },
  {
    image: Blockchain,
    mobile: BlockchainMobile,
    title: "Press Two 69696969",
    description:
      "Maecenas cursus ultrices nibh, eu fringilla leo vulputate ut. Duis convallis ullamcorper nisi, non blandit eros rutrum eu. Morbi et",
    calender: Calender,
    person: Person,
    chat: Chat,
  },
  {
    image: Chain,
    mobile: ChainMobile,
    title: "Press Three 69696969",
    description:
      "Maecenas cursus ultrices nibh, eu fringilla leo vulputate ut. Duis convallis ullamcorper nisi, non blandit eros rutrum eu. Morbi et",
    calender: Calender,
    person: Person,
    chat: Chat,
  },
  {
    image: Blockchain,
    mobile: BlockchainMobile,
    title: "Press Four 69696969",
    description:
      "Maecenas cursus ultrices nibh, eu fringilla leo vulputate ut. Duis convallis ullamcorper nisi, non blandit eros rutrum eu. Morbi et",
    calender: Calender,
    person: Person,
    chat: Chat,
  },
];

function PressPage() {
  if (isMobile) {
    return (
      <div className="w-screen md:h-screen " id="press">
        <div className="ml-10 my-32">
          <div className="font-semibold text-[1.5rem]  text-white flex justify-start">
            <div>Nverse in the Press</div>
          </div>
          <div className="pressMObaSwiper">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={25}
              autoplay={{ delay: 1500, disableOnInteraction: false }}
              // modules={[Autoplay]}
              className="mySwiper pressMobaSwiper"
            >
              {list.map((value) => (
                <div>
                  <SwiperSlide>
                    <div className="text-white">
                      <img src={value.mobile} alt="chain-logo" className="" />
                      <div className="text-left mx-1 my-4">
                        <div className="text-[1rem] font-semibold mb-4">
                          {value.title}
                        </div>
                        <div className="text-[0.8rem]">{value.description}</div>
                        <div className="text-[0.7rem] mt-4 flex justify-between">
                          <div className="flex gap-0 items-center">
                            <img
                              src={value.calender}
                              alt="logo"
                              className="scale-75"
                            />
                            <p>Today</p>
                          </div>
                          <div className="flex gap-0 items-center">
                            <img
                              src={value.person}
                              alt="logo"
                              className="scale-75"
                            />
                            <p>RockyGestruk</p>
                          </div>
                          <div className="flex gap-0 items-center">
                            <img
                              src={value.chat}
                              alt="logo"
                              className="scale-75"
                            />
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-screen h-screen my-32 -mt-4" id="press">
      <br />
      <br />
      <br />
      <br />
      <div className="ml-20 ">
        <div className="font-semibold text-[2rem] p-4 text-white flex justify-start">
          <div>Nverse in the Press</div>
        </div>
        <div className="pressSwiper">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            // modules={[Autoplay]}
            className="mySwiper pressSwiper"
          >
            {list.map((value) => (
              <div>
                <SwiperSlide>
                  <div className="text-white flex">
                    <img src={value.image} alt="chain-logo" className="" />
                    <div className="text-left mx-3 my-4">
                      <div className="text-[1.5rem] font-semibold mb-4">
                        {value.title}
                      </div>
                      <div>{value.description}</div>
                      <div className="flex justify-between absolute bottom-0 gap-[1rem] mb-[2%]">
                        <div className="flex">
                          <img
                            src={value.calender}
                            alt="logo"
                            className="w-6 h-6 mr-2"
                          />
                          <p>Today</p>
                        </div>
                        <div className="flex">
                          <img
                            src={value.person}
                            alt="logo"
                            className="w-5 h-5 mr-2"
                          />
                          <p>RockyGestruk</p>
                        </div>
                        <div className="flex">
                          <img
                            src={value.chat}
                            alt="logo"
                            className="w-5 h-5 mr-2"
                          />
                          <p>2</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default PressPage;
