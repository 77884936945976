import React, { useState } from "react";
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
// import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Pagination, Mousewheel, Navigation } from "swiper";

import FirstPage from "./FIrst";
import BuildByNverse from "./BuildByNverse";
import MeetTheTeam from "./MeetTheTeam";
import Footer from "../../components/footer";
import PartnersPage from "../partners";
import PressPage from "../press";
import Navbar from "../../components/Navbar";
import "../../css/swiper.css";

function Home1() {
  const isMobile = window.innerWidth < 768;
  const [isEnd, setIsEnd] = useState(false);
  const [slide, setSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const slideTo = (index) => swiper.slideTo(index);

  return (
    <div className="w-screen h-screen">
      <div className="hidden lg:block">
        <Navbar />
      </div>
      <Swiper
        speed={1000}
        spaceBetween={30}
        onSwiper={setSwiper}
        hashNavigation={{
          watchState: true,
        }}
        onSlideChange={() => {
          setSlide(swiper.activeIndex);
          setIsEnd(swiper.isEnd);
        }}
        direction={isMobile ? "vertical" : "horizontal"}
        effect={"fade"}
        mousewheel={true}
        navigation={false}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Pagination, Mousewheel, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <FirstPage />
        </SwiperSlide>
        <SwiperSlide data-hash="slide2">
          <BuildByNverse />
        </SwiperSlide>
        <SwiperSlide>
          <div className="text-white w-full h-full flex justify-center items-center">
            <MeetTheTeam />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <PartnersPage />
        </SwiperSlide>
        <SwiperSlide>
          <div className="text-white w-full h-full flex justify-center items-center">
            <PressPage />
          </div>
        </SwiperSlide>
      </Swiper>
      {!isMobile && <Footer slideTo={slideTo} slide={slide} isEnd={isEnd} />}
    </div>
  );
}

export default Home1;
