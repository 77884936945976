import React from "react";
import FirstPage from "./FIrst";
import BuildByNverse from "./BuildByNverse";
import MeetTheTeam from "./MeetTheTeam";
import Footer from "../../components/footer";
// import PartnersPage from "../partners";
// import PressPage from "../press";
import Navbar from "../../components/Navbar";

function Home() {
  return (
    <div className="bg-gradient-to-r from-[#2B2E82] via-[#121158] to-[#0A0918]">
      <div className="">
        <Navbar />
        <FirstPage />
        <BuildByNverse />
        <MeetTheTeam />
        {/* <PartnersPage />
        <PressPage /> */}
        <Footer />
      </div>
    </div>
  );
}

export default Home;
