import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

import logo from "../assets/icon/footer/nverse-logo.png";
// import facebook from "../assets/icon/footer/facebook.png";
import twitter from "../assets/icon/footer/twitter.png";
import linkedin from "../assets/icon/linkedin.svg";
// import instagram from "../assets/icon/footer/instagram.png";
import emailIcon from "../assets/icon/footer/email.png";
import iconleft from "../assets/icon/footer/icon-left.png";

function Footer() {
  const [email, setEmail] = useState("");
  const [date] = useState(new Date().toLocaleDateString());
  function handleClick() {
    const objt = { email, date };

    axios
      .post(
        "https://sheet.best/api/sheets/fd81bf6c-add6-443d-a860-78713c2d75d8",
        objt
      )
      .then(() => {
        // console.log(response);
        swal("Email Added", "Email Added Successfully", "success");
      });
  }
  return (
    <div className="w-screen h-screen md:h-[300px] 2xl:h-[760px] bg-[#F9F9F9] overflow-hidden">
      <div className="mx-10 md:mx-20 2xl:mx-30 pt-10 flex md:flex flex-col md:justify-between md:flex-row md:items-center 2xl:items-center">
        <div className="text-left 2xl:text-left">
          <img
            src={logo}
            alt="nverse-logo"
            className="2xl:scale-[2] 2xl:ml-16"
          />
          <p className="text-[#5B5B5B] pt-4 2xl:text-[1.3vw] 2xl:pt-12">
            Advancing The Digital Asset Revolution
          </p>
          <div className="pt-6 2xl:pt-20">
            <div className="flex justify-between p-4 w-[10rem]">
              <a
                href="https://www.linkedin.com/company/nverselabs/"
                target="blank"
              >
                <img src={linkedin} alt="facebook" className="2xl:scale-[2]" />
              </a>
              <a href="https://twitter.com/TheNverseLabs" target="blank">
                <img
                  src={twitter}
                  alt="facebook"
                  className="2xl:scale-[2] pt-1"
                />
              </a>
              {/* <a href="#">
                <img src={instagram} alt="facebook" className="2xl:scale-[2]" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="text-left pt-8 2xl:text-left 2xl:pt-20">
          <div className="text-[1rem] md:text-[1.5rem] 2xl:text-[3.5rem] font-semibold">
            Nverse Labs
          </div>
          <div className="text-[#5B5B5B] mt-4 text-[0.85rem] 2xl:text-[1vw] flex flex-col">
            <a href="#built" className="my-3">
              Built by Nverse
            </a>
            <a href="#team" className="my-3">
              Team
            </a>
            {/* <a href="#" className="my-3">
              Partners
            </a>
            <p className="my-3">Press</p> */}
          </div>
        </div>
        <div className="pt-6 2xl:pt-4">
          <div className="text-[1.2rem] md:text-[1.5rem] 2xl:text-[2vw] font-medium text-left">
            Subcribe to our <br />
            newsletter!
          </div>
          <div className="flex gap-4 mt-6">
            <div className="flex gap-4 2xl:gap-12 border-0 p-2 rounded-2xl bg-[#FFFFFF] justify-center 2xl:justify-start items-center w-[90%] md:w-[80%] 2xl:w-full 2xl:p-6">
              <div className="flex justify-center items-center">
                <img
                  src={emailIcon}
                  alt="email"
                  className="h-6 w-6 2xl:scale-[2]"
                />
              </div>
              <div className="text-left">
                <p className="text-[#5B5B5B] text-[0.8rem] 2xl:text-[2.6rem]">
                  Your Email
                </p>
                <input
                  type="email"
                  id="emailNews"
                  placeholder="Contact@nverse.io"
                  className="text-[0.8rem] 2xl:text-[2.6rem] md:text-[1.2rem] font-semibold"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* <p className="text-[0.8rem] md:text-[1.2rem] font-semibold">
                  RockyGestruk@Nverse.io
                </p> */}
              </div>
            </div>
            <button onClick={handleClick} type="button">
              <div className="2xl:pt-12">
                <img
                  src={iconleft}
                  alt="icon-left"
                  className="scale-90 2xl:scale-[2]"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
